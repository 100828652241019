<template>
  <section class="container store">
    <div class="row">
      <div class="col-12 col-md-6 maps">
        <div class="box-maps">
          <GmapMap
            v-if="stores"
            :center="centerMaps"
            :zoom="12"
            :options="{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUi: false
            }"
            map-type-id="terrain"
            class="maps" >

            <gmap-marker
              v-for="store in stores"
              :key="store.id"
              :position="store.latLong"
              :icon="store.iconMarker" >
            </gmap-marker>
          </GmapMap>
        </div>
      </div>

      <div class="col-12 col-md-6 content">
        <img src="@/assets/icons/ic-find-store.svg">
        <h2>FIND TRUECOFFEE GO</h2>
        <p>
          Connect your lifestyle with TrueCoffee GO,<br>
          find our branches near you.
        </p>
        <div class="route">
          <router-link
            id="homepageStoreLink"
            :to="{ name: 'store' }">
            <span>FIND STORE</span>
            <div class="line"></div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  data () {
    return {
      stores: [],
      centerMaps: {
        lat: 13.7567046,
        lng: 100.499701
      },
      isLoadPage: false,
      markerActive: require('@/assets/images/marker-cup.png'),
      markerComing: require('@/assets/images/marker-cup-coming.png')
    }
  },

  computed: {
    ...mapState({
      rawStores: state => state.store.rawStores
    })
  },

  methods: {
    async mapStore (stores) {
      // this.markerActive = require('@/assets/images/marker-cup.png')
      // this.markerComing = require('@/assets/images/marker-cup-coming.png')

      if (stores) {
        this.stores = await []
        let arrCities = await []
        let arrStores = await []

        await stores.forEach(async (store, i) => {
          let now = await moment()
          let willTime = await moment(store.date_publish)
          let diff = await moment.duration(willTime.diff(now))

          let status = await null

          let iconMarker = ''

          if (diff._data.years <= 0 &&
            diff._data.months <= 0 &&
            diff._data.days <= 0 &&
            diff._data.hours <= 0 &&
            diff._data.minutes <= 0 &&
            diff._data.seconds <= 0) {
            status = 'active'
            this.isComing = true
            iconMarker = await this.markerActive
          } else {
            status = 'coming'
            this.isComing = false
            iconMarker = await this.markerComing
          }

          let addressSplitlength = await store.address.split(' ').length
          let city = await store.address.split(' ')[addressSplitlength - 3]
          let postcode = await store.address.split(' ')[addressSplitlength - 1]

          arrCities.push(city)

          let obj = await {
            id: store.id,
            name: store.branch,
            address: store.address,
            city: city,
            postcode: postcode,
            latLong: {
              lat: parseFloat(store.lat_long.split(',')[0]),
              lng: parseFloat(store.lat_long.split(',')[1])
            },
            iconMarker: iconMarker,
            addressMaps: store.address_maps,
            time: store.time,
            directUrl: store.map_url,
            status: status
          }

          await arrStores.push(obj)
        })

        let newArrCities = await [...new Set(arrCities)]

        this.filterCity = await 'All'
        this.cities = await newArrCities
        await this.cities.unshift('All')
        this.showCities = await newArrCities
        this.stores = await arrStores
      }
    }
  },

  watch: {
    rawStores (stores) {
      this.mapStore(stores)
    }
  },

  // beforeCreate () {
  //   this.markerActive = require('@/assets/images/marker-cup.png')
  //   this.markerComing = require('@/assets/images/marker-cup-coming.png')
  // },

  created () {
    this.$store.dispatch('store/getStore')
  },

  mounted () {
    setTimeout(() => {
      this.isLoadPage = true
    }, 2000)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variable.scss';

.store {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 0;

  @media screen and (min-width: 768px) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .row {
    width: 100%;

    @media screen and (min-width: 768px) {
      flex-direction: row-reverse;
    }

    .maps {
      padding: 0;
      height: 100%;

      @media screen and (min-width: 768px) {
        height: 350px;
      }

      .box-maps {
        transform: scale(0.92);
        border-style: solid;
        border-color: black;
        border-width: 3px 0 0 3px;
        width: 100%;
        height: 300px;

        @media only screen and (min-width: 768px) {
          height: auto;
        }
      }

      .box-maps:after,
      .box-maps:before {
        content: '';
        background: $color-main;
        position: absolute;
      }

      .box-maps:after {
        box-sizing: border-box;
        width: calc(100% + 3px);
        height: 6px;
        left: 0;
        bottom: 0;
        border-style: solid;
        border-color: black;
        border-width: 2px 2px 2px 2.5px;
        transform: translatey(100%) skewx(45deg);

        @media only screen and (min-width: 768px) {
          left: 0;
          bottom: 2px;
          width: calc(100% + 3px);
        }
      }

      .box-maps:before {
        width: 6px;
        height: calc(100% + 3px);
        right: 0;
        transform: translatex(100%) skewy(45deg);
        border-style: solid;
        border-color: black;
        border-width: 4px 2px 2px 2px;
        top: 0;

        @media only screen and (min-width: 768px) {
          top: 0;
          height: 100%;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 38px;

      @media screen and (min-width: 768px) {
        margin-top: 0;
      }

      img {
        width: 45px;
        height: auto;
      }

      h2 {
        margin: 12px 0;
        font-family: 'DB Ozone X Bd';
        font-size: 32px;

        @media screen and (min-width: 768px) {
          font-size: 60px;
        }
      }

      .route {
        margin: 20px 0;
        width: fit-content;

        span {
          font-family: 'DB Ozone X Bd';
          font-size: 18px;
        }

        .line {
          margin-top: -6px;
          width: 50%;
          height: 2px;
          background-color: $color-main;
          transition: 800ms $ease;
        }
      }

      .route:hover {
        .line {
          width: 100%;
        }
      }
    }
  }
}
</style>
