var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"container store"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 maps"},[_c('div',{staticClass:"box-maps"},[(_vm.stores)?_c('GmapMap',{staticClass:"maps",attrs:{"center":_vm.centerMaps,"zoom":12,"options":{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false
          },"map-type-id":"terrain"}},_vm._l((_vm.stores),function(store){return _c('gmap-marker',{key:store.id,attrs:{"position":store.latLong,"icon":store.iconMarker}})}),1):_vm._e()],1)]),_c('div',{staticClass:"col-12 col-md-6 content"},[_c('img',{attrs:{"src":require("@/assets/icons/ic-find-store.svg")}}),_c('h2',[_vm._v("FIND TRUECOFFEE GO")]),_vm._m(0),_c('div',{staticClass:"route"},[_c('router-link',{attrs:{"id":"homepageStoreLink","to":{ name: 'store' }}},[_c('span',[_vm._v("FIND STORE")]),_c('div',{staticClass:"line"})])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Connect your lifestyle with TrueCoffee GO,"),_c('br'),_vm._v(" find our branches near you. ")])}]

export { render, staticRenderFns }